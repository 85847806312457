function homeBoxesHeight(){
	var elements = jQuery('.home-cats .item > a .row');

	if( !isBreakpoint('xs') ){
		elements.height( '' );
		var maxHeight = Math.max.apply(null, elements.map(function(){ return jQuery(this).height(); }).get());
		elements.height( maxHeight );
	}
	else{
		elements.height( '' );
	}
}

jQuery(document).ready(function($) {
	jQuery('.home-slider .slick').slick({
		dots: true,
		arrows: false,
		autoplay: false,
		autoplaySpeed: 5000,
		pauseOnHover: false,
		fade: false,
		infinite: true
	});

	jQuery('.home-cats .item .image').imagesLoaded( { background: true }, function() {
		homeBoxesHeight();
	});

	jQuery('.tooltipster').tooltipster({
		arrow: false,
		side: ['left'],
		trigger: 'hover',
		theme: 'tooltipster-light'
	});
});
jQuery(window).resize(function(){
	homeBoxesHeight();
});
