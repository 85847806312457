function isBreakpoint( alias ) {
    return jQuery('.device-' + alias).is(':visible');
}

var currentBreakpoint;

jQuery(document).ready(function(){
	currentBreakpoint =  jQuery('.is-breakpoint:visible').data('breakpoint');
});

jQuery(window).resize(function(){
	if( currentBreakpoint !==  jQuery('.is-breakpoint:visible').data('breakpoint') ){
		currentBreakpoint =  jQuery('.is-breakpoint:visible').data('breakpoint');

		jQuery(window).trigger('breakpointChanged');
	}
});