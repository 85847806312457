jQuery(document).ready(function() {
	if (window.devicePixelRatio > 1) {
		var re = /(?:\.([^.]+))?$/;
        var lowresImages = jQuery('img.img-retina');
        lowresImages.map(function(i) {
            var self = jQuery(this);

        	var thisSrc = self.attr('src');
            var fileExt = '.' + re.exec( thisSrc )[1];
            
            jQuery(this).attr('src', thisSrc.replace(fileExt, '') + '@2x' + fileExt);
        });
    }
});
