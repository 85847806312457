jQuery(document).ready(function($) {
	if( $('.page-gmap').length>0 ){

		var markers = new Array();
		$.getScript('https://maps.googleapis.com/maps/api/js?key=' + gMapsApiKey, function( data, textStatus, jqxhr ){
			$('.page-gmap').each(function(index){
				var map,
					marker,
					draggableOpt = true,
					$this = $(this),
					lat = $this.data('lat'),
    				lng = $this.data('lng'),
					centerLat = $this.data('center-lat'),
    				centerLng = $this.data('center-lng'),
    				zoom = $this.data('zoom'),
    				infoWindows = [];

    			if( !(centerLat && centerLng) ){
    				centerLat = 0;
    				centerLng = 0;
    			}

    			if( zoom=='' ){
    				zoom = 10;
    			}

				$this.append('<div id="map_' + index + '"></div>');

				map = new google.maps.Map(document.getElementById('map_' + index), {
					zoom: zoom,
					center: {
						lat: centerLat,
						lng: centerLng
					},
					draggable: true,
					scrollwheel: false,
					
					zoomControl: true,
					mapTypeControl: false,
					scaleControl: false,
					streetViewControl: false,
					rotateControl: false,
					fullscreenControl: true,

					zoomControlOptions: {
						style: google.maps.ZoomControlStyle.SMALL,
						position: google.maps.ControlPosition.LEFT_TOP
					},
					streetViewControlOptions: {
						position: google.maps.ControlPosition.LEFT_TOP
					},
					styles: [
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "hue": "#e9ebed"
            },
            {
                "saturation": -78
            },
            {
                "lightness": 67
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "hue": "#ffffff"
            },
            {
                "saturation": -100
            },
            {
                "lightness": 100
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "hue": "#bbc0c4"
            },
            {
                "saturation": -93
            },
            {
                "lightness": 31
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            },
            {
                "hue": "#ffffff"
            },
            {
                "saturation": -100
            },
            {
                "lightness": 100
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "hue": "#e9ebed"
            },
            {
                "saturation": -90
            },
            {
                "lightness": -8
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "hue": "#e9ebed"
            },
            {
                "saturation": 10
            },
            {
                "lightness": 69
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "hue": "#2c2e33"
            },
            {
                "saturation": 7
            },
            {
                "lightness": 19
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "hue": "#bbc0c4"
            },
            {
                "saturation": -93
            },
            {
                "lightness": 31
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "hue": "#bbc0c4"
            },
            {
                "saturation": -93
            },
            {
                "lightness": -2
            }
        ]
    }
]
				});
				
				var bounds = new google.maps.LatLngBounds();

				$this.find('.marker').each(function(index){
					var markerPng = $(this).data('png'),
    					markerSvg = $(this).data('svg'),
						markerWidth = $(this).data('width'),
    					markerHeight = $(this).data('height'),
    					group = $(this).data('group'),
						is_internetExplorer11= navigator.userAgent.toLowerCase().indexOf('trident') > -1,
						marker_url = ( is_internetExplorer11 ) ? markerPng : markerSvg;

					var infowindow = new google.maps.InfoWindow({
						content: $(this).html()
					});

					var marker = new google.maps.Marker({
						position: new google.maps.LatLng($(this).data('lat'), $(this).data('lng')),
						map: map,
						icon: {
							url: marker_url,
							// size: new google.maps.Size(markerWidth, markerHeight),
							// anchor: new google.maps.Point(0, markerHeight)
						},
						zIndex: $(this).data('zindex')
					});

					marker.addListener('click', function() {
						for (var i=0;i<infoWindows.length;i++) {
							infoWindows[i].close();
						}
						infoWindows.push(infowindow);
						infowindow.open(map, marker);
					});

					markers[index] = {
						'group': group,
						'marker': marker
					}

					bounds.extend( marker.position );
				});

				if( centerLat==0 && centerLng==0 ){
					if( markers.length>1 ){
						map.fitBounds(bounds);
					}
					var listener = google.maps.event.addListener(map, "idle", function () {
						google.maps.event.removeListener(listener);
					});
				}
			});
		});

		$('.map-controls > span').click(function(e){
			e.preventDefault();

			for(var i=0; i<markers.length; i++){
				if( markers[i].group==$(this).data('group') ){
					if( $(this).hasClass('active') ){
						markers[i].marker.setVisible(false);
					}
					else{
						markers[i].marker.setVisible(true);
					}
				}
			}

			$(this).toggleClass('active');
		});
	}
});
